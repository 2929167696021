import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  faCalendar,
  faCheckCircle,
  faCircle,
  faFilter,
  faMagnifyingGlass,
  faSync,
  faTimes,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { iconClassMap } from 'src/app/shared/models/iconClassMap';
import { keys } from '../keys';
import { FormsModule } from '@angular/forms';
import { AutocompleteComponent } from '../../ui-components/autocomplete/autocomplete.component';
import { TagsComponent } from '../../ui-components/tags/tags.component';
import { NgIf, NgFor } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { KeyType } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { UserCardComponent } from '../../ui-components/user-card/user-card.component';

@Component({
  selector: 'app-commission-statistics-filter',
  templateUrl: './commission-statistics-filter.component.html',
  styleUrls: ['./commission-statistics-filter.component.scss'],
  standalone: true,
  imports: [
    AutocompleteComponent,
    FaIconComponent,
    FormsModule,
    NgFor,
    NgIf,
    TagsComponent,
    UserCardComponent,
  ],
})
export class CommissionStatisticsFilterComponent {
  @Input() data: any;
  @Input() filter: any;

  @Input() hiddenKeyMap: { [key: string]: boolean } = {};

  @Input() loadingChanged?: EventEmitter<any>;
  @Output() filterChanged = new EventEmitter<any>();

  faCheckCircle = faCheckCircle;
  faCircle = faCircle;
  faMagnifyingGlass = faMagnifyingGlass;
  faSync = faSync;
  faTimes = faTimes;
  faTriangleExclamation = faTriangleExclamation;

  production = environment.production;

  filterHasBeenUpdated = false;

  showAdvancedFilter = false;

  resultsAgent?: any;

  dateRange = {
    start: null,
    end: this.getDateString(new Date()),
  };
  // dateOptions = [
  signatureDateOptions = [
    {
      id: 'all',
      title: 'All',
    },
    {
      id: 'today',
      title: 'Today',
    },
    {
      id: 'yesterday',
      title: 'Yesterday',
    },
    {
      id: 'last7days',
      title: 'Last 7 Days',
    },
    {
      id: 'last30days',
      title: 'Last 30 Days',
    },
    {
      id: 'last90days',
      title: 'Last 90 Days',
    },
    {
      id: 'last365days',
      title: 'Last 365 Days',
    },
    {
      id: 'custom',
      title: 'Custom',
    },
  ];

  signatureDateRange: { start: string | null; end: string | null } = {
    start: null,
    end: this.getDateString(new Date()),
  };

  // signatureDateOptions = [
  //   {
  //     id: 'all',
  //     title: 'All',
  //   },
  //   {
  //     id: 'AEP',
  //     title: 'AEP',
  //   },
  //   {
  //     id: 'OEP',
  //     title: 'OEP',
  //   },
  //   {
  //     id: 'ROY',
  //     title: 'ROY',
  //   },
  //   {
  //     id: 'custom',
  //     title: 'Custom',
  //   },
  // ];

  monthValues = [
    { id: '1', title: 'January' },
    { id: '2', title: 'February' },
    { id: '3', title: 'March' },
    { id: '4', title: 'April' },
    { id: '5', title: 'May' },
    { id: '6', title: 'June' },
    { id: '7', title: 'July' },
    { id: '8', title: 'August' },
    { id: '9', title: 'September' },
    { id: '10', title: 'October' },
    { id: '11', title: 'November' },
    { id: '12', title: 'December' },
  ];

  yearValues = [
    { id: '2021', title: '2021' },
    { id: '2022', title: '2022' },
    { id: '2023', title: '2023' },
    { id: '2024', title: '2024' },
    { id: '2025', title: '2025' },
    { id: '2026', title: '2026' },
    { id: '2027', title: '2027' },
    { id: '2028', title: '2028' },
    { id: '2029', title: '2029' },
    { id: '2030', title: '2030' },
  ];

  faCalendar = faCalendar;
  faFilter = faFilter;
  keys = keys;
  iconClassMap = iconClassMap;

  KeyType = KeyType;

  showFilter = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.cdr.detach();
    this.loadingChanged?.subscribe(loading => {
      if (loading === false) {
        this.filterHasBeenUpdated = false;
      }
    });
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.cdr.detectChanges();
    }
  }

  removeFromFilter(tagName: string, id: string) {
    this.filter[tagName] = this.filter[tagName].filter((x: any) => x.id !== id);
    this.onFilterChange(tagName, null);
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.cdr.detectChanges();
  }

  onDateChange() {
    console.log('dateRange', this.dateRange);
    this.filter.dateRange = this.dateRange;
    this.onFilterChange('date', null);
  }
  onSignatureDateChange() {
    console.log('signatureDateRange', this.signatureDateRange);
    this.filter.signatureDateRange = this.signatureDateRange;

    if (this.signatureDateRange.start && this.signatureDateRange.end) {
      const startYear = this.signatureDateRange.start.split('-')[0];
      const endYear = this.signatureDateRange.end.split('-')[0];

      console.log('Years', startYear.length, endYear.length);
      if (startYear.length === 4 && endYear.length === 4) {
        this.onFilterChange('signatureDate', null);
      }
    }
  }

  onFilterChange(tagName: string, event: any, forceScroll = false) {
    // this.filterChanged.emit({ forceScroll });
    this.filterHasBeenUpdated = true;
    this.cdr.detectChanges();

    if (
      // ['date', 'signatureDate'].includes(tagName) &&
      ['cycle-name'].includes(tagName) &&
      event &&
      event !== 'custom'
    ) {
      // if (event?.id === 'all') {
      //   this.filter[tagName] = [{ id: 'all', name: 'All' }];
      // }

      this.filterChanged.emit({ forceScroll, tagName });
      this.filterHasBeenUpdated = false;
      this.cdr.detectChanges();
    }
  }

  getDateString(date: Date) {
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${date.getFullYear()}-${month}-${day}`;
  }

  onDateFilterChange(name: string, event: any) {
    const splitted = event.split('-');
    const year = parseInt(splitted[0]);

    if (year > 1000 && year < 3000) {
      this.onFilterChange(name, event);
    }
  }

  toggleAdvancedFilter() {
    this.showAdvancedFilter = !this.showAdvancedFilter;
    this.cdr.detectChanges();
  }

  search() {
    this.showFilter = false;
    this.filterChanged.emit();
  }

  // getCycleItems = () => [
  //   { id: 'all', name: 'All' },
  //   { id: 'bababa', name: 'Bababa' },
  //   ...this.data.data['cycle-name'],
  // ];

  resultsChangeAgent(event: any) {
    this.resultsAgent = event;
  }
}
