@if (data && data.data && data.data['cycle-name']) {
  <div class="form-control w-full mb-2">
    <!-- <label class="label">
      <span class="label-text"
        ><fa-icon [icon]="faSync" class="mr-2"></fa-icon> Statement Date
      </span>
    </label> -->
    <!-- <app-tags
      label="Statement Date"
      [items]="data.data['cycle-name']"
      bindLabel="name"
      bindValue=""
      [deselectable]="true"
      [small]="true"
      [(value)]="filter['cycle-name']"
      (valueChange)="onFilterChange('cycle-name', $event)"></app-tags> -->
    <app-autocomplete
      title="Statement Date"
      [icon]="faSync"
      placeholder="Select Statement Date"
      bindLabel="name"
      bindValue="id"
      type="multiselect"
      [items]="data.data['cycle-name']"
      [showSelectAll]="true"
      [small]="true"
      [(value)]="filter['cycle-name']"
      (valueChange)="onFilterChange('cycle-name', $event)">
    </app-autocomplete>
  </div>
}

<div
  class="collapse collapse-arrow mb-4 overflow-visible z-20"
  [class.collapse-open]="showFilter">
  <div class="collapse-title" (click)="toggleFilter()">
    <h4><fa-icon [icon]="faFilter" class="mr-2"></fa-icon>Filters</h4>
    <p>Filters can be added or removed as you want</p>
  </div>
  @if (showFilter) {
    <div
      class="fixed top-0 left-0 bottom-0 right-0 z-0"
      (click)="toggleFilter()"></div>
  }
  <div class="collapse-content z-10">
    @if (data?.data) {
      <div class="grid md:grid-cols-3 gap-4">
        <div class="md:col-span-3">
          <div class="form-control w-full mb-2">
            <label class="label">
              <span class="label-text"
                ><fa-icon [icon]="faCalendar" class="mr-2"></fa-icon> Signature
                Date
              </span>
            </label>
            <app-tags
              label="Date"
              [radio]="true"
              [items]="signatureDateOptions"
              bindLabel="title"
              bindValue="id"
              [small]="true"
              [(value)]="filter.signatureDate"
              (valueChange)="
                onFilterChange('signatureDate', $event)
              "></app-tags>
          </div>

          @if (filter.signatureDate === 'custom') {
            <div class="grid md:grid-cols-3 gap-4 mb-2">
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">start date</span>
                </label>
                <input
                  type="date"
                  [(ngModel)]="signatureDateRange.start"
                  (ngModelChange)="onSignatureDateChange()"
                  placeholder="enter here"
                  class="input input-bordered w-full max-w-xs" />
              </div>
              <div class="form-control w-full max-w-xs">
                <label class="label">
                  <span class="label-text">end date</span>
                </label>
                <input
                  type="date"
                  [(ngModel)]="signatureDateRange.end"
                  (ngModelChange)="onSignatureDateChange()"
                  placeholder="enter here"
                  class="input input-bordered w-full max-w-xs" />
              </div>
              <div class="form-control w-full">
                <label class="label">
                  <span class="label-text">&nbsp;</span>
                </label>
                <button
                  class="btn"
                  [class.btn-primary]="filterHasBeenUpdated"
                  [class.btn-neutral]="!filterHasBeenUpdated"
                  (click)="search()">
                  <fa-icon [icon]="faMagnifyingGlass" class="mr-2"></fa-icon>
                  Search
                </button>
              </div>
            </div>
          }
        </div>
        <!-- <div class="form-control w-full">
          <label class="label">
            <span class="label-text">&nbsp;</span>
          </label>
          @if (showAdvancedFilter) {
            <button
              type="button"
              class="btn btn-outline w-full"
              (click)="toggleAdvancedFilter()">
              <fa-icon [icon]="faCheckCircle" class="mr-2"></fa-icon>
              Hide Advanced Filters
            </button>
          } @else {
            <button
              type="button"
              class="btn btn-outline w-full"
              (click)="toggleAdvancedFilter()">
              <fa-icon [icon]="faCircle" class="mr-2"></fa-icon>
              Show Advanced Filters
            </button>
          }
        </div> -->
        <div>
          <app-autocomplete
            #agentAutocomplete
            title="Agent"
            placeholder="Select User"
            bindLabel="name"
            bindValue="id"
            type="multiselect"
            [hideSelected]="true"
            [items]="data.data['agent-id']"
            [(value)]="filter['user-id']"
            (valueChange)="onFilterChange('user-id', $event)">
          </app-autocomplete>

          @if (filter['user-id']) {
            <div class="flex flex-wrap gap-2">
              @for (v of filter['user-id']; track v) {
                <span class="badge badge-outline h-auto rounded-box pl-0">
                  <span class="badge-content">
                    <app-user-card [userId]="v.id"></app-user-card>
                  </span>
                  <button
                    type="button"
                    class="btn btn-ghost btn-xs rounded-btn"
                    (click)="agentAutocomplete.remove(v)">
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                </span>
              }
            </div>
          }
        </div>

        @for (k of keys; track k) {
          @if (!hiddenKeyMap[k.name] && (!k.isAdvanced || showAdvancedFilter)) {
            @if (!k.autocomplete && !k.hideFilter) {
              <div class="form-control w-full">
                <label class="label">
                  <span class="label-text">
                    @if (k.iconClass && iconClassMap[k.iconClass]) {
                      <fa-icon
                        [icon]="iconClassMap[k.iconClass]"
                        class="mr-2"></fa-icon>
                    } @else {
                      <fa-icon
                        [icon]="faTimes"
                        class="text-error mr-2"></fa-icon>
                    }
                    <span>{{ k.title }}</span>
                  </span>
                </label>
                @if (k.type === KeyType.K_DATE) {
                  <input
                    type="date"
                    [placeholder]="k.title"
                    class="input input-bordered"
                    [(ngModel)]="filter[k.name]"
                    (ngModelChange)="onDateFilterChange(k.name, $event)" />
                }
                @if (!k.type || k.type !== KeyType.K_DATE) {
                  <app-tags
                    [title]="k.title"
                    bindLabel="name"
                    bindValue=""
                    type="multiselect"
                    [items]="data.data[k.name]"
                    [(value)]="filter[k.name]"
                    (valueChange)="onFilterChange(k.name, $event)">
                  </app-tags>
                }
              </div>
            }
            @if (k.autocomplete && !k.hideFilter) {
              <app-autocomplete
                [title]="k.title"
                [placeholder]="'Select ' + k.title"
                bindLabel="name"
                bindValue="id"
                type="multiselect"
                [lookupMap]="k.lookupMap"
                [items]="data.data[k.name]"
                [(value)]="filter[k.name]"
                (valueChange)="onFilterChange(k.name, $event)">
              </app-autocomplete>
            }
          }
        }

        @if (showAdvancedFilter) {
          <!-- <div class="md:col-span-3">
            <h4>
              <fa-icon [icon]="faCalendar" class="mr-2"></fa-icon>
              Effective Date
            </h4>
            <div class="grid md:grid-cols-3 gap-2">
              <app-autocomplete
                title="Month"
                placeholder="Select Month"
                bindLabel="title"
                bindValue="id"
                type="multiselect"
                [items]="monthValues"
                [(value)]="filter.effectiveDateMonth"
                (valueChange)="onFilterChange('effectiveDateMonth', $event)">
              </app-autocomplete>
              <app-autocomplete
                title="Year"
                placeholder="Select Year"
                bindLabel="title"
                bindValue="id"
                type="multiselect"
                [items]="yearValues"
                [(value)]="filter.effectiveDateYear"
                (valueChange)="onFilterChange('effectiveDateYear', $event)">
              </app-autocomplete>
            </div>
          </div> -->
        }

        <div class="form-control w-full">
          <label class="label">
            <span class="label-text">&nbsp;</span>
          </label>
          <button
            class="btn"
            [class.btn-primary]="filterHasBeenUpdated"
            [class.btn-neutral]="!filterHasBeenUpdated"
            (click)="search()">
            <fa-icon [icon]="faMagnifyingGlass" class="mr-2"></fa-icon>
            Search
          </button>
        </div>
      </div>
    }
  </div>
</div>
<div class="pt-24 -mt-24"></div>

@if (!showFilter && filterHasBeenUpdated) {
  <div class="card card-compact card-warning z-50 mb-4">
    <div class="card-body flex flex-row">
      <div class="grow flex items-center">
        <fa-icon [icon]="faTriangleExclamation" class="mr-2"></fa-icon>

        <h3>Filters has been updated</h3>
      </div>
      <button class="btn btn-white bg-white" (click)="search()">
        <fa-icon [icon]="faMagnifyingGlass" class="mr-2"></fa-icon>
        Search
      </button>
    </div>
  </div>
}
